import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Route,
} from "react-router-dom";
import {
  AboutUs,
  ContactUs,
  CookiePolicy,
  DonatePage,
  Home,
  Media,
  Membership,
  NotFoundPage,
  PrivacyPolicy,
  EventDetail,
  TermsAndConditions,
  Events,
  Programs,
  Rsvp,
  Resources,
  DonationSuccess,
} from "./pages";

import Layout from "./Layouts/Layout";
import EventDetailLayout from "./Layouts/EventDetailLayout";
import Register from "./pages/register";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Layout />}>
      <Route index exact element={<Home />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/contact-us" element={<ContactUs />} />
      <Route path="/media" element={<Media />} />
      <Route path="/membership" element={<Membership />} />
      <Route path="/events" element={<Events />} />

      <Route path="/events" element={<EventDetailLayout />}>
        <Route path="/events/:eventId" element={<EventDetail />} />
      </Route>

      <Route path="/programs" element={<Programs />} />

      <Route path="/donate" element={<DonatePage />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/cookie-policy" element={<CookiePolicy />} />
      <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
      <Route path="/rsvp" element={<Rsvp />} />
      <Route path="/resources" element={<Resources />} />
      <Route path="/register" element={<Register />} />
      <Route path="/donation-success" element={<DonationSuccess />} />

      <Route path="*" element={<NotFoundPage />} />
    </Route>
  )
);

function App() {
  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
