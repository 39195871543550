import React, { useState, useEffect, lazy, Suspense } from "react";
import "./events.css";
import { ScrollToTop, Countdown } from "../../components";
import UpcomingEventsSkeleton from "../../components/skeletons/UpcomingEventsSkeleton";
import EventCardSkeleton from "../../components/skeletons/EventCardSkeleton";

const UpcomingEvents = lazy(() => import("../../components/upcoming-events"));
const EventCard = lazy(() => import("../../components/event-card"));

const Events = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate data loading
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <section className="eventsSection">
        <div className="container">
          <div className="header">
            <h1>Events</h1>
          </div>
          {isLoading ? (
            <>
              <UpcomingEventsSkeleton />
              <EventCardSkeleton />
            </>
          ) : (
            <>
              <Suspense fallback={<div>Loading...</div>}>
                <UpcomingEvents />
                <EventCard />
              </Suspense>
            </>
          )}
        </div>
      </section>
      <Countdown />
      <ScrollToTop />
    </>
  );
};

export default Events;
