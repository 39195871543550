import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";

const DonationWrapper = () => {
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
  const [amount, setAmount] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/config`).then(async (r) => {
      const { publishableKey } = await r.json();
      setStripePromise(loadStripe(publishableKey));
    });
  }, []);

  const handleAmountChange = (e) => {
    const value = e.target.value;
    setAmount(value);
    if (parseFloat(value) < 10) {
      setError("Minimum donation amount is $10");
    } else {
      setError("");
    }
  };

  const createPaymentIntent = async () => {
    if (parseFloat(amount) < 10) {
      setError("Minimum donation amount is $10");
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/create-payment-intent`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            amount: Math.round(parseFloat(amount) * 100),
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const { clientSecret } = await response.json();
      setClientSecret(clientSecret);
    } catch (err) {
      console.error("Failed to create PaymentIntent:", err);
      setError("Failed to create payment. Please try again.");
    }
  };

  const appearance = {
    theme: "stripe",
  };

  const options = {
    clientSecret,
    appearance,
  };

  if (!clientSecret) {
    return (
      <div>
        <input
          type="number"
          value={amount}
          onChange={handleAmountChange}
          placeholder="Enter donation amount ($)"
          step="0.01"
          min="10"
          required
        />
        {error && <div className="error">{error}</div>}
        <button
          onClick={createPaymentIntent}
          disabled={!amount || parseFloat(amount) < 10}
        >
          Set Amount
        </button>
      </div>
    );
  }

  return (
    stripePromise &&
    clientSecret && (
      <Elements options={options} stripe={stripePromise}>
        <CheckoutForm amount={amount} />
      </Elements>
    )
  );
};

export default DonationWrapper;
