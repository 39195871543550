import React, { useState, useEffect } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
import "./navbar.css";
import { LogoWhite } from "../../assets";
import { navigationLinks } from "../../constants/links";
import { FaAlignRight } from "react-icons/fa";
import { MdOutlineClose } from "react-icons/md";

const Navbar = () => {
  const [isNavToggle, setIsNavToggle] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [openSubmenu, setOpenSubmenu] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggle = () => setIsNavToggle(!isNavToggle);
  const hide = () => setIsNavToggle(false);
  const show = () => setIsNavToggle(true);

  const handleDonateClick = () => {
    navigate("/donate?tab=stripe");
  };

  const isActive = location.pathname === "/donate";

  const handleSubmenuToggle = (id) => {
    if (isMobile) {
      setOpenSubmenu(openSubmenu === id ? null : id);
    }
  };

  return (
    <>
      <nav className="navbar">
        <div className="container">
          <Link to="/" className="nav__logo">
            <img loading="lazy" src={LogoWhite} alt="Logo" className="logo" />
          </Link>
          <ul
            className={`nav__items ${isNavToggle ? "show__nav" : "hide__nav"}`}
          >
            {navigationLinks.map((link) => {
              if (link.submenu) {
                return (
                  <li key={link.id} className="nav__item-with-submenu">
                    <NavLink
                      to={link.url}
                      className={({ isActive }) => (isActive ? "active" : "")}
                      onClick={() => handleSubmenuToggle(link.id)}
                    >
                      {link.name}
                    </NavLink>
                    <ul
                      className={`submenu ${
                        isMobile && openSubmenu === link.id
                          ? "show-submenu"
                          : ""
                      }`}
                    >
                      {link.submenu.map((sublink) => (
                        <li key={sublink.id}>
                          <NavLink
                            to={sublink.url}
                            className={({ isActive }) =>
                              isActive ? "active" : ""
                            }
                            onClick={toggle}
                          >
                            {sublink.name}
                          </NavLink>
                        </li>
                      ))}
                    </ul>
                  </li>
                );
              }
              return (
                <li key={link.id}>
                  <NavLink
                    to={link.url}
                    className={({ isActive }) => (isActive ? "active" : "")}
                    onClick={toggle}
                    onBlur={hide}
                    onFocus={show}
                  >
                    {link.name}
                  </NavLink>
                </li>
              );
            })}
            <button
              onClick={handleDonateClick}
              className={`${
                isActive ? "active donateNavBtnActive" : "donateNavBtn"
              }`}
            >
              Donate
            </button>
          </ul>

          <button
            id="open__nav-btn"
            onClick={() => setIsNavToggle(!isNavToggle)}
          >
            {isNavToggle ? <MdOutlineClose /> : <FaAlignRight />}
          </button>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
