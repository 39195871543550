import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
import { footerMenuLinks, navigationLinks } from "../../constants/links";
import { FaEnvelope, FaMapMarker, FaSistrix } from "react-icons/fa";
import { FaFacebookF, FaTwitter, FaInstagramSquare } from "react-icons/fa";
import { RiMapPinFill } from "react-icons/ri";

import { LogoWhite } from "../../assets";
import "./footer.css";

const Footer = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  const handleSearch = (event) => {
    event.preventDefault();
    // Redirect to Resources page with search term as query parameter
    navigate(`/resources?search=${searchTerm}`);
  };

  const today = new Date();
  const year = today.getFullYear();
  return (
    <>
      <footer>
        <div className="container">
          <div className="top">
            <div className="left">
              <div className="companyDetail">
                <div className="logo">
                  <img loading="lazy" src={LogoWhite} alt="logo" />
                </div>
                <div className="description">
                  <p>
                    Mesh of mothers foundation was founded in 2021 during COVID
                    19 pandemic as an organization to bridge the gap and create
                    a positive environment and services to meet the needs of
                    African Canadians mothers and women in Edmonton and Alberta
                    as a whole.
                  </p>
                </div>
              </div>
            </div>
            <div className="right">
              <div className="top">
                <div className="socials">
                  <Link className="icons" target="_blank" to={"./"}>
                    <FaTwitter className="icon" />
                  </Link>
                  <Link
                    className="icons"
                    target="_blank"
                    to={
                      "https://www.instagram.com/meshofmothersfoundation/?hl=en"
                    }
                  >
                    <FaInstagramSquare className="icon" />
                  </Link>
                  <Link
                    className="icons"
                    target="_blank"
                    to={"https://www.facebook.com/meshofmothersfoundation/"}
                  >
                    <FaFacebookF className="icon" />
                  </Link>
                  <Link className="icons" to={"./contact-us"}>
                    <RiMapPinFill className="icon" />
                  </Link>
                  <Link className="icons" to="mailto:info@meshofmothers.ca">
                    <FaEnvelope className="icon" />
                  </Link>
                  {/* <Link className="icons" target="_blank">
                    <FaYoutube className="icon" />
                  </Link>
                  <Link className="icons" target="_blank">
                    <FaTiktok className="icon" />
                  </Link> */}
                </div>
              </div>
              <div className="bottom">
                <div className="menuWrapper">
                  <div className="menuItem">
                    <div className="header">
                      <h3>{footerMenuLinks.supportLinks.title}</h3>
                    </div>
                    <div className="menuList">
                      {footerMenuLinks.supportLinks.links.map((links) => {
                        return (
                          <Link
                            className="service_menu_item"
                            key={links.id}
                            to={links.url}
                          >
                            {links.name}
                          </Link>
                        );
                      })}
                    </div>
                  </div>
                  <div className="menuItem">
                    <div className="header">
                      <h3>{footerMenuLinks.menuLinks.title}</h3>
                    </div>
                    <div className="menuList">
                      {footerMenuLinks.menuLinks.links.map((links) => {
                        return (
                          <Link
                            className="service_menu_item"
                            key={links.id}
                            to={links.url}
                          >
                            {links.name}
                          </Link>
                        );
                      })}
                    </div>
                  </div>
                  <div className="menuItem">
                    <div className="header">
                      <h3>contact us</h3>
                    </div>
                    <div className="address">
                      <article>
                        <p>
                          Edmonton, Alberta <br />
                          Canada <br />
                          info@meshofmothers.ca
                        </p>
                      </article>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bottom">
            <div className="search-bar">
              <form onSubmit={handleSearch}>
                <input
                  type="text"
                  name="search"
                  placeholder="Search Here..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <button className="searchButton" type="submit">
                  <FaSistrix className="searchIcon" />
                </button>
              </form>
            </div>
            <div className="menu-items">
              {navigationLinks.map((links) => {
                return (
                  <Link className="menu_item" key={links.id} to={links.url}>
                    {links.name}
                  </Link>
                );
              })}

              {/* <NavLink
                to={"https://buy.stripe.com/aEUaIKggr2b3ci4fYY"}
                className="footerDonateNavBtn"
              >
                Donate
              </NavLink> */}
            </div>
            <div className="copyright">
              <div className="copy-name">
                &copy; {year} <Link to={"./"}> Mesh Of Mothers</Link> | Designed
                & developed by |
                <a
                  href="http://www.veltetech.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {""} Velte Technology Solutions
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
