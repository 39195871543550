import React, { lazy, Suspense, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./event-detail.css";
import { Countdown, ScrollToTop } from "../../components";
import { events } from "../../constants/data";
import EventDetailSkeleton from "../../components/skeletons/EventDetailSkeleton";
import ProgressiveImage from "../../components/ProgressiveImage";

const Gallery = lazy(() => import("../../components/gallery"));
const YoutubeFrame = lazy(() => import("../../components/youtubeFrame"));

const EventDetail = () => {
  const { eventId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [ourEvents, setOurEvents] = useState(null);

  useEffect(() => {
    const fetchEvent = async () => {
      // Simulate API call
      await new Promise((resolve) => setTimeout(resolve, 1000));
      const foundEvent = events.find((event) => event.id === eventId);
      setOurEvents(foundEvent);
      setIsLoading(false);
    };

    fetchEvent();
  }, [eventId]);

  if (isLoading) {
    return (
      <>
        <EventDetailSkeleton />
        <Countdown />
        <ScrollToTop />
      </>
    );
  }

  if (!ourEvents) {
    return <div>Event not found</div>;
  }

  return (
    <>
      <section className="eventDetailSection">
        <div className="container">
          <div className="eventHeader hor">
            <div className="left ver flex">
              <div className="eventTitle">
                <h1>{ourEvents.title}</h1>
              </div>
            </div>
            <div className="right ver flex">
              <div className="imgWrapper">
                <ProgressiveImage
                  lowQualitySrc={ourEvents.lowQualityImage}
                  highQualitySrc={ourEvents.image}
                  alt={ourEvents.title}
                />
              </div>
            </div>
          </div>
          <div className="eventDetail">
            <p>{ourEvents.details}</p>
          </div>
          <div className="eventVideo">
            <Suspense fallback={<div>Loading video...</div>}>
              <YoutubeFrame link={ourEvents.youtubeURL} />
            </Suspense>
          </div>
          <div className="eventPictures">
            <div className="header">
              <h3>Gallery</h3>
            </div>
            <Suspense fallback={<div>Loading gallery...</div>}>
              <Gallery galleryImages={ourEvents.pictures} />
            </Suspense>
          </div>
        </div>
      </section>
      <Countdown />
      <ScrollToTop />
    </>
  );
};

export default EventDetail;
