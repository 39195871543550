import React, { useState, useEffect, lazy } from "react";
import { useNavigate } from "react-router-dom";
import "./home.css";
import { homeContent } from "../../constants/data";
import { Countdown, ScrollToTop } from "../../components";
import { girlsGroupImg } from "../../assets";
import { FaSistrix } from "react-icons/fa";
import { HashLink as Link } from "react-router-hash-link";

import HomeSkeleton from "../../components/skeletons/HomeSkeleton";
import ProgressiveImage from "../../components/ProgressiveImage";

const BipocModal = lazy(() => import("../../components/modal/BipocModal"));
const HamperModal = lazy(() => import("../../components/modal/HamperModal"));

const Home = () => {
  const [openBipocModal, setOpenBipocModal] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);

  const [showModal, setShowModal] = useState(false);

  const handleSearch = (event) => {
    event.preventDefault();
    // Redirect to Resources page with search term as query parameter
    navigate(`/resources?search=${searchTerm}`);
  };

  useEffect(() => {
    // Set showModal to true on page load
    setShowModal(true);
  }, []); // Empty dependency array ensures the effect runs only once on mount

  const closeBipocModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    // Simulate loading time
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  if (isLoading) {
    return <HomeSkeleton />;
  }

  return (
    <>
      <section className="showcase pt-6">
        <div className="container">
          <div className="hor">
            <div className="ver">
              <div className="content">
                <div className="header">
                  <h1 className="blueDark">{homeContent.showcaseHeader1}</h1>
                  <br />
                  <h1 className="red">{homeContent.showcaseHeader2}</h1>
                </div>
                <div className="txtContent">
                  <article>
                    <h3>{homeContent.showcaseHeader3}</h3>
                    <p>{homeContent.showcaseParagraph}</p>
                  </article>
                </div>
                <div className="btnHolder">
                  <Link to={"/about-us"} className="btnPrimary">
                    read more
                  </Link>
                </div>
              </div>
            </div>
            <div className="ver">
              <div className="imgWrapper">
                <img
                  loading="lazy"
                  src={homeContent.showcaseImg}
                  alt={homeContent.showcaseHeader3}
                />
                {/* <ProgressiveImage
                  lowQualitySrc={homeContent.showcaseImg.lowQuality}
                  highQualitySrc={homeContent.showcaseImg.highQuality}
                  alt={homeContent.showcaseHeader3}
                /> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="mothering">
        <div className="container">
          <div className="hor">
            <div className="ver offsetImg">
              <div className="imgWrapper">
                <img
                  loading="lazy"
                  src={homeContent.motheringImg}
                  alt={homeContent.motheringP1}
                />
              </div>
            </div>
            <div className="ver justify-center">
              <div className="content">
                <div className="header">
                  <h1>{homeContent.motheringHeader}</h1>
                </div>
                <div className="txtContent">
                  <article>
                    <p className="bold">{homeContent.motheringP1}</p>
                    <p>{homeContent.motheringP2}</p>
                  </article>
                </div>
                <div className="btnHolder">
                  <Link to={"/about-us"} className="btnPrimary">
                    our values
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="empowered">
        <div className="container">
          <div className="hor">
            <div className="ver">
              <div className="content">
                <div className="header">
                  <h1>{homeContent.empoweredHeader1}</h1>
                  <br />
                  <h1>{homeContent.empoweredHeader2}</h1>
                </div>
              </div>
              <div className="txtContent">
                <article>
                  <p className="bold">{homeContent.empoweredP1}</p>
                  <p>{homeContent.empoweredP2}</p>
                </article>
              </div>
              <div className="btnHolder">
                <Link to={"/programs"} className="btnPrimary">
                  our programs
                </Link>
              </div>
            </div>
            <div className="ver offsetImg">
              <div className="imgWrapper">
                <img
                  loading="lazy"
                  src={homeContent.empoweredImg}
                  alt={
                    homeContent.empoweredHeader1 + homeContent.empoweredHeader2
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="support">
        <div className="container">
          <div className="hor">
            <div className="ver">
              <div className="imgWrapper">
                <img
                  loading="lazy"
                  src={homeContent.supportCauseImg}
                  alt={homeContent.supportCauseHeader}
                />
              </div>
            </div>
            <div className="ver">
              <div className="content">
                <div className="header">
                  <h1>{homeContent.supportCauseHeader}</h1>
                </div>
                <div className="txtContent">
                  <article>
                    <p>{homeContent.supportCauseParagraph}</p>
                  </article>
                </div>
              </div>
            </div>
            <div className="ver">
              <div className="btnHolder bigBtn">
                <Link to={"/donate"} className="btnSecondary">
                  donate
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="buildingTogether">
        <div className="container">
          <div className="ceoWrapper">
            <div className="hor">
              <div className="ver">
                <div className="imgWrapper">
                  <img
                    loading="lazy"
                    src={homeContent.ceoSection.image}
                    alt={homeContent.ceoSection.subTitle}
                  />
                </div>
              </div>
              <div className="ver">
                <div className="content">
                  <div className="header">
                    <h1>{homeContent.ceoSection.title}</h1>
                  </div>
                  <div className="txtContent">
                    <article>
                      <h3>{homeContent.ceoSection.subTitle}</h3>
                      <p>{homeContent.ceoSection.details}</p>
                    </article>
                  </div>
                  <div className="btnHolder">
                    <Link
                      to={"/about-us#architects"}
                      className="btnPrimary"
                      // scroll={(el) =>
                      //   el.scrollIntoView({ behavior: "auto", block: "end" })
                      // }
                    >
                      meet the board
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="top">
            <div className="hor">
              <div className="ver">
                <div className="content">
                  <div className="header">
                    <h1 className="brown">
                      {homeContent.buildingTogetherHeader}
                    </h1>
                  </div>
                  <div className="txtContent">
                    <article>
                      <h3 className="red">
                        {homeContent.buildingTogetherHeader2}
                      </h3>
                      <p>{homeContent.buildingTogetherParagraph}</p>
                    </article>
                  </div>
                </div>
              </div>
              <div className="ver">
                <div className="imgWrapper">
                  <img
                    loading="lazy"
                    src={homeContent.buildingTogetherImg}
                    alt={homeContent.buildingTogetherHeader}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="bottom">
            <div className="row mt-4">
              {homeContent.partnerLogos.map((partners, index) => (
                <div className="row col gap-4 partnerLogo" key={index}>
                  {partners?.map((partner, index) => (
                    <div className=" mb-5" key={index}>
                      <img
                        loading="lazy"
                        src={partner.logo}
                        alt={partner.alt}
                        className="img-fluid"
                      />
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <section className="mediaMembership">
        <div className="container">
          <div className="hor">
            <div className="ver ">
              <div className="search-bar">
                <form onSubmit={handleSearch}>
                  <input
                    type="text"
                    name="search"
                    placeholder="Search Here..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  <button className="searchButton dimBkg" type="submit">
                    <FaSistrix className="searchIcon" />
                  </button>
                </form>
              </div>
            </div>

            <div className="ver">
              <div className="content">
                <div className="header">
                  <h1>{homeContent.mediaMemHeader}</h1>
                </div>
                <div className="txtContent">
                  <article>
                    <p>{homeContent.mediaMemParagraph}</p>
                  </article>
                </div>
              </div>
            </div>

            <div className="ver offsetImg">
              <div className="imgWrapper">
                <img
                  loading="lazy"
                  src={homeContent.mediaMemImg}
                  alt={homeContent.mediaMemHeader}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="girlGroup">
        <div className="container">
          <div className="imgWrapper">
            <img loading="lazy" src={girlsGroupImg} alt="girl group" />
          </div>
        </div>
      </section>

      <section className="joinSection">
        <div className="container">
          <div className="hor">
            <div className="ver offsetImg">
              <div className="imgWrapper">
                <img
                  loading="lazy"
                  src={homeContent.joinSectionImg}
                  alt={homeContent.joinSectionHeader}
                />
              </div>
            </div>
            <div className="ver">
              <div className="content">
                <div className="header">
                  <h1>{homeContent.joinSectionHeader}</h1>
                </div>
                <div className="txtContent">
                  <article>
                    <p>{homeContent.joinSectionParagraph}</p>
                  </article>
                </div>
                <div className="btnHolder">
                  <Link to={"/membership"} className="btnPrimary">
                    become a member
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* {openBipocModal && <BipocModal closeBipocModal={setOpenBipocModal} />} */}
      {/* <BipocModal
        showBipocModal={showModal}
        closeBipocModal={closeBipocModal}
      /> */}
      {/* <HamperModal
        showHamperModal={showModal}
        closeHamperModal={closeBipocModal}
      /> */}
      <Countdown />
      <ScrollToTop />
    </>
  );
};

export default Home;
