import React from "react";
import "./media.css";
import { Countdown, ScrollToTop } from "../../components";

const Media = () => {
  return (
    <>
      <div>Media</div>
      <Countdown />
      <ScrollToTop />
    </>
  );
};

export default Media;
