import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./resources.css";
import { RxCaretLeft } from "react-icons/rx";
import { RxCaretRight } from "react-icons/rx";
import { categories, jobs } from "./data";
import JobFilterTabs from "./JobFilterTabs";
import { BsEnvelopeAtFill, BsFillClockFill } from "react-icons/bs";
import { HiUser } from "react-icons/hi2";
import { MdLocationPin, MdWorkspacePremium, MdWork } from "react-icons/md";
import { FaPhoneAlt, FaGlobe, FaSuitcase } from "react-icons/fa";
import { ScrollToTop } from "../../components";
import JobViewModal from "../../components/modal/JobViewModal";
import defaultPic from "../../assets/images/default-avatar.png";
import SelectedJob from "../../components/SelectedJob";

const Resources = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const searchTermQueryParam = queryParams.get("search") || "";
  const [searchTerm, setSearchTerm] = useState(searchTermQueryParam);
  const [originalSearchTerm, setOriginalSearchTerm] =
    useState(searchTermQueryParam);

  const [filteredJobs, setFilteredJobs] = useState(jobs);
  const [selectedJob, setSelectedJob] = useState(null);
  const [activeTab, setActiveTab] = useState(categories[0]);
  const [currentPage, setCurrentPage] = useState(1);
  const [jobsPerPage] = useState(10);
  const [showModal, setShowModal] = useState(false);

  const handleTabChange = (category) => {
    setActiveTab(category);
    filterJobs(category, originalSearchTerm);
  };

  const handleSearchChange = (event) => {
    const term = event.target.value;
    setSearchTerm(term);
    filterJobs(activeTab, term);
  };

  const handleSearchSubmit = () => {
    setOriginalSearchTerm(searchTerm);
    filterJobs(activeTab, searchTerm);
  };

  const handleJobClick = (job) => {
    setSelectedJob(job);
    setShowModal(true);
  };

  const closeJobViewModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    filterJobs(activeTab, searchTerm);
  }, [activeTab, searchTerm]);

  const filterJobs = (category, term) => {
    let filtered;
    if (category === "All") {
      filtered = jobs.filter((job) =>
        job.profession.toLowerCase().includes(term.toLowerCase())
      );
    } else {
      filtered = jobs.filter(
        (job) =>
          job.category === category &&
          job.profession.toLowerCase().includes(term.toLowerCase())
      );
    }
    setFilteredJobs(filtered);
    setCurrentPage(1); // Reset to first page when filtering changes
    // setSearchTerm("");
  };

  // Pagination logic
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(filteredJobs.length / jobsPerPage); i++) {
    pageNumbers.push(i);
  }

  const indexOfLastJob = currentPage * jobsPerPage;
  const indexOfFirstJob = indexOfLastJob - jobsPerPage;
  const currentJobs = filteredJobs.slice(indexOfFirstJob, indexOfLastJob);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  function truncateText(text, maxLength) {
    if (text.length <= maxLength) {
      return text;
    }
    return text.substring(0, maxLength) + "...";
  }
  const maxLength = 100;
  return (
    <>
      <div className="job-search-app">
        <div className="container">
          <h1>Resources</h1>
          <input
            type="text"
            placeholder="Search resources..."
            value={searchTerm}
            onChange={handleSearchChange}
            className="job-search-input"
          />
          <JobFilterTabs
            categories={categories}
            activeTab={activeTab}
            onTabChange={handleTabChange}
          />

          <hr />
          <div className="jobs-wrapper">
            <div className="job-listing">
              <div className="job-results">
                {currentJobs.map((job, index) => (
                  <div
                    key={index}
                    className="job-item"
                    onClick={() => handleJobClick(job)}
                  >
                    <h3>{`${job.Prefix} ${job.firstName} ${job.lastName}`}</h3>
                    <div className="detail-info">
                      <div className="detail-info-item">
                        <MdWorkspacePremium className="icon" />
                      </div>
                      <div className="detail-info-item">
                        <p className="profession">{job.profession}</p>
                      </div>
                    </div>
                    <p className="bio">{truncateText(job.bio, maxLength)}</p>
                  </div>
                ))}
              </div>
              <div className="pagination">
                <button
                  onClick={() => paginate(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  <RxCaretLeft className="icon" />
                </button>
                {pageNumbers.map((number) => (
                  <button
                    key={number}
                    onClick={() => paginate(number)}
                    className={currentPage === number ? "active" : ""}
                  >
                    {number}
                  </button>
                ))}
                <button
                  onClick={() => paginate(currentPage + 1)}
                  disabled={indexOfLastJob >= filteredJobs.length}
                >
                  <RxCaretRight className="icon" />
                </button>
              </div>
            </div>

            <SelectedJob
              selectedJob={selectedJob}
              specialClassName="displayNoneMob"
            />
          </div>
        </div>
      </div>
      <JobViewModal
        showJobViewModal={showModal}
        closeJobViewModal={closeJobViewModal}
        selectedJob={selectedJob}
      />
      <ScrollToTop />
    </>
  );
};

export default Resources;
