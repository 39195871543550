import React, { useState } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";

const CheckoutForm = ({ amount }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [succeeded, setSucceeded] = useState(false);
  const [message, setMessage] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setProcessing(true);

    try {
      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${window.location.origin}/donation-success`,
        },
      });

      if (error) {
        setError(`Payment failed: ${error.message}`);
        setMessage(error.message);
      } else {
        setError(null);
        setSucceeded(true);
      }
    } catch (err) {
      setError(`Payment failed: ${err.message}`);
      setMessage(err.message);
    } finally {
      setProcessing(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <h3>Donation Amount: ${amount}</h3>
      <PaymentElement id="payment-element" />
      {error && <div className="error">{error}</div>}
      {succeeded && <div className="success">Payment succeeded!</div>}
      <button type="submit" disabled={!stripe || processing || succeeded}>
        {processing ? "Processing..." : `Donate $${amount}`}
      </button>
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
};

export default CheckoutForm;
